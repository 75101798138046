import "../store/store.scss";

import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import getAlertChannels from "../../services/alertChannelService";
import getBlurrings from "../../services/blurringService";
import getButtonsTypes from "../../services/buttonsTypesService";
import getDeepModels from "../../services/deepModelService";
import { restartLocationContainer } from "../../services/locationService";
import getSlackChannels from "../../services/slackChannelService";
import getStoreManagers from "../../services/storeManagerService";
import { deleteStore, saveStore } from "../../services/storeService";
import getUploadBuckets from "../../services/uploadBucketService";
import getUploadTargets from "../../services/uploadTargetService";
import DisplayWhen from "../common/base/DisplayWhen";
import BaseForm from "../common/BaseForm";
import Store from "../models/StoreModel";
import { editorRole, qualityRole } from "../shared/constants";
import FormDataContext from "../shared/contexts/FormDataContext";
import userHasRole from "../shared/utils/authUtils";
import FieldBuilder from "../shared/utils/FieldBuilder";
import ResponsiveRow from "./base/ResponsiveRow";
import ToggleSwitch from "../common/controls/ToggleSwitch/ToggleSwitch";

class StoreConfigForm extends BaseForm {
  store = new Store();
  state = {
    data: {},
    showNewLabels: true, // TODO set to true once new labels have been implemented and deployed everywhere
    toggleConfigsOldLabel: [
      { name: "no_suspicious", value: "Slightly suspicious", inverted: true, datagroup: "suspiciousDatagroup" },
      { name: "no_stroller", value: "Stroller", inverted: true, datagroup: "productIntoStrollerDatagroup" },
      { name: "send_store_normal_bag", value: "Store normal bag", datagroup: "storeNormalBagDatagroup" },
      { name: "send_gesture_into_body", value: "Gesture into body", datagroup: "gestureIntoBodyDatagroup" },
      { name: "send_client_normal_bag", value: "Client normal bag", datagroup: "clientNormalBagDatagroup" },
      { name: "no_suspicious_bag", value: "Suspicious bag", inverted: true, datagroup: "suspiciousBagDatagroup" },
    ],
    toggleConfigBodyParent: {
      name: "bodyToggleGroup",
      value: "Body labels",
      parentgroup: "bodyToggleGroup",
    },
    toggleConfigsNewLabelBody: [
      // Always active : hide the toggle
      // { name: "body_theft", value: "Body theft", disabled: true, childgroup: "bodyToggleGroup" },
      {
        name: "gesture_into_body",
        value: "Gesture into body",
        datagroup: "gestureIntoBodyDatagroup",
        childgroup: "bodyToggleGroup",
      },
    ],
    toggleConfigBagParent: { name: "bagToggleGroup", value: "Bag labels", parentgroup: "bagToggleGroup" },
    toggleConfigsNewLabelBag: [
      // Always active : hide the toggle
      // { name: "product_in_personal_bag", value: "Product in personal bag", childgroup: "bagToggleGroup" },
      { name: "product_into_backpack", value: "Product into backpack", childgroup: "bagToggleGroup" },
      {
        name: "suspicious_bag",
        value: "Suspicious bag",
        datagroup: "suspiciousBagDatagroup",
        childgroup: "bagToggleGroup",
      },
      {
        name: "product_into_stroller",
        value: "Product into stroller",
        datagroup: "productIntoStrollerDatagroup",
        childgroup: "bagToggleGroup",
      },
      {
        name: "client_normal_bag",
        value: "Client normal bag",
        datagroup: "clientNormalBagDatagroup",
        childgroup: "bagToggleGroup",
      },
      {
        name: "store_normal_bag",
        value: "Store normal bag",
        datagroup: "storeNormalBagDatagroup",
        childgroup: "bagToggleGroup",
      },
    ],
    toggleConfigOthersusParent: {
      name: "othersusToggleGroup",
      value: "Other suspicious labels",
      datagroup: "suspiciousDatagroup",
      parentgroup: "othersusToggleGroup",
    },
    toggleConfigsNewLabelOtherSus: [
      { name: "deblistering", value: "Deblistering", childgroup: "othersusToggleGroup" },
      { name: "consumption", value: "Consumption", childgroup: "othersusToggleGroup" },
      { name: "burst_shot", value: "Burst shot", childgroup: "othersusToggleGroup" },
      { name: "other_suspicious", value: "Other", childgroup: "othersusToggleGroup" },
    ],
    toggleConfigsDisplay: [
      { name: "show_cam_number", value: "Show cam number" },
      { name: "show_latency", value: "Show latency" },
      { name: "show_alert_time", value: "Show alert time" },
      { name: "show_alert_type", value: "Show alert type" },
    ],
    alertChannels: [],
    deepModels: [],
    slackChannels: [],
    storeManagers: [],
    errors: {},
    buttonsTypes: [],
    uploadTargets: [],
    uploadBuckets: [],
    blurrings: [],
    redirectStore: null,
    showLocalInferenceModal: false,
  };
  schema = this.store.getSchema();

  async fetchButtonsTypes() {
    // eslint-disable-next-line camelcase
    const { data: buttons_types } = await getButtonsTypes();
    // eslint-disable-next-line camelcase
    return buttons_types;
  }

  async fetchAlertChannels() {
    let { data: alertChannels } = await getAlertChannels();
    alertChannels = alertChannels.map((alertChannel) => {
      if (alertChannel.name !== "RabbitMQ - label - DNS") {
        return { ...alertChannel, enabled: false };
      }
      return alertChannel;
    });
    alertChannels.unshift({ id: 0, name: "Not allocated" });
    return alertChannels;
  }

  async fetchBlurring() {
    const { data: blurrings } = await getBlurrings();
    blurrings.unshift({ id: 0, name: "No blurring" });
    return blurrings;
  }

  async fetchSlackChannels() {
    const { data: slackChannels } = await getSlackChannels();
    slackChannels.unshift({ id: "0", name: "Not allocated" });
    return slackChannels;
  }

  async fetchDeepModels() {
    const { data: deepModels } = await getDeepModels();
    deepModels.unshift({ id: 0, name: "Use default" });
    return deepModels;
  }

  async fetchStoreManager() {
    const { data: storeManagers } = await getStoreManagers();
    return storeManagers;
  }

  async fetchUploadTargets() {
    const { data: uploadTargets } = await getUploadTargets();
    uploadTargets.unshift({ id: "0", name: "Not allocated" });
    return uploadTargets;
  }

  async fetchUploadBuckets() {
    const { data: uploadBuckets } = await getUploadBuckets();
    uploadBuckets.unshift({ id: "0", name: "Not allocated" });
    return uploadBuckets;
  }

  /**
   * Fetches all data needed by this component.
   * All requests are parallelized.
   *
   * @returns {Promise<void>}
   */
  fetchAll = async () => {
    const [
      alertChannels,
      blurrings,
      deepModels,
      slackChannels,
      storeManagers,
      buttonsTypes,
      uploadTargets,
      uploadBuckets,
    ] = await Promise.all([
      this.fetchAlertChannels(),
      this.fetchBlurring(),
      this.fetchDeepModels(),
      this.fetchSlackChannels(),
      this.fetchStoreManager(),
      this.fetchButtonsTypes(),
      this.fetchUploadTargets(),
      this.fetchUploadBuckets(),
    ]);

    this.setState({
      alertChannels,
      blurrings,
      deepModels,
      slackChannels,
      storeManagers,
      buttonsTypes,
      uploadTargets,
      uploadBuckets,
    });
  };

  isCheckboxChecked(data, checkbox) {
    const checkboxValue = data[checkbox.name];
    return checkbox.getAttribute("inverted") ? !checkboxValue : checkboxValue;
  }

  handleDatagroupToggled(data, checkbox, datagroup, checked) {
    let newData = data;
    const sameDataGroupCheckboxes = this.getCheckboxesByGroup("datagroup", datagroup);
    sameDataGroupCheckboxes.forEach((sameDataGroupCheckbox) => {
      if (sameDataGroupCheckbox.name === checkbox.name) {
        return;
      }
      if (this.isCheckboxChecked(newData, sameDataGroupCheckbox) === this.isCheckboxChecked(newData, checkbox)) {
        return;
      }
      newData = this.toggleCheckbox(newData, sameDataGroupCheckbox, checked);
      // if a checkbox from the same datagroup has a parent, handle the parent toggling
      const sameDataGroupCheckboxChildGroup = sameDataGroupCheckbox.getAttribute("childgroup");
      if (sameDataGroupCheckboxChildGroup) {
        newData = this.handleChildToggled(newData, sameDataGroupCheckbox, sameDataGroupCheckboxChildGroup);
      }
      // if a checkbox from the same datagroup is a parent, handle the children toggling
      const sameDataGroupCheckboxParentgroup = sameDataGroupCheckbox.getAttribute("parentgroup");
      if (sameDataGroupCheckboxParentgroup) {
        newData = this.handleParentToggled(newData, sameDataGroupCheckbox, sameDataGroupCheckboxParentgroup);
      }
    });
    return newData;
  }

  handleParentToggled(data, checkbox, parentgroup) {
    let newData = data;
    const childGroupCheckboxes = this.getCheckboxesByGroup("childgroup", parentgroup);
    childGroupCheckboxes.forEach((childCheckbox) => {
      newData = this.toggleCheckbox(newData, childCheckbox, this.isCheckboxChecked(newData, checkbox));
      const childDataGroup = childCheckbox.getAttribute("datagroup");
      // if the child checkbox belongs to a datagroup, all checkboxes with same datagroup should change
      if (childDataGroup) {
        newData = this.toggleCheckboxesByGroup(
          newData,
          "datagroup",
          childDataGroup,
          this.isCheckboxChecked(newData, checkbox),
          childCheckbox
        );
      }
    });
    return newData;
  }

  handleChildToggled(data, childCheckbox, childgroup) {
    let newData = data;
    const parentGroupName = childgroup;
    const parentCheckbox = this.getCheckboxesByGroup("parentgroup", parentGroupName)[0];
    if (!parentGroupName) {
      return newData;
    }
    if (!parentCheckbox) {
      return newData;
    }
    let checked = "undefined";
    if (this.isCheckboxChecked(newData, childCheckbox)) {
      // When checking a child toggle, check the parent toggle
      checked = true;
      newData = this.toggleCheckbox(newData, parentCheckbox, checked);
    } else {
      // When unchecking a child toggle, if all child toggles are unchecked, uncheck the parent too
      const childGroupCheckboxes = document.querySelectorAll(
        `[childgroup="${childCheckbox.getAttribute("childgroup")}"]`
      );
      let allUnchecked = true;
      childGroupCheckboxes.forEach((childGroupCheckbox) => {
        if (this.isCheckboxChecked(newData, childGroupCheckbox)) {
          allUnchecked = false;
        }
      });
      if (allUnchecked) {
        checked = false;
        newData = this.toggleCheckbox(newData, parentCheckbox, checked);
      }
    }
    // if parent of child belongs to a datagroup, handle datagroup toggling
    const parentDatagroup = parentCheckbox.getAttribute("datagroup");
    if (parentDatagroup && typeof checked !== "undefined") {
      newData = this.handleDatagroupToggled(newData, parentCheckbox, parentDatagroup, checked);
    }
    return newData;
  }

  handleCheckboxToggled(data, checkbox, initial) {
    let newData = data;
    const datagroup = checkbox.getAttribute("datagroup");
    const parentgroup = checkbox.getAttribute("parentgroup");
    const childgroup = checkbox.getAttribute("childgroup");

    // if the checkbox belongs to a datagroup, all checkboxes with same datagroup should change
    if (datagroup) {
      newData = this.handleDatagroupToggled(newData, checkbox, datagroup);
    }

    // if the checkbox is a parentgroup, all the childgroup checkboxes should change
    if (parentgroup && !initial) {
      newData = this.handleParentToggled(newData, checkbox, parentgroup);
    }

    // if a checkbox belongs to a childgroup, handle the parent toggling
    // if it belongs to a datagroup too, its datagroup should have been already handled above
    if (childgroup) {
      newData = this.handleChildToggled(newData, checkbox, childgroup);
    }
    return newData;
  }

  toggleCheckboxesByGroup(data, groupType, groupName, checked, excludedCheckbox) {
    let newData = data;
    const groupCheckboxes = this.getCheckboxesByGroup(groupType, groupName);
    groupCheckboxes.forEach((groupCheckbox) => {
      if (this.isCheckboxChecked(newData, groupCheckbox) === checked) {
        return;
      }
      if (excludedCheckbox && groupCheckbox.name === excludedCheckbox.name) {
        return;
      }
      newData = this.toggleCheckbox(newData, groupCheckbox, checked);
    });
    return newData;
  }

  toggleCheckbox(data, checkbox, checked) {
    if (!checkbox.disabled) {
      let newValue = !data[checkbox.name];
      if (typeof checked !== "undefined") {
        newValue = checkbox.getAttribute("inverted") ? !checked : checked;
      }
      data[checkbox.name] = newValue;
    }
    return data;
  }

  getCheckboxesByGroup(groupType, groupName) {
    return document.querySelectorAll(`[${groupType}="${groupName}"]`);
  }

  setCheckboxesEventListener() {
    const checkboxes = document.querySelectorAll("input[type=checkbox]");
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", () => {
        let newData = this.state.data;
        newData = this.handleCheckboxToggled(newData, checkbox);
        this.setState({ data: newData });
      });
    });
  }

  setCheckboxesInitialValues(data, initial) {
    let newData = data;
    const checkboxes = document.querySelectorAll("input[type=checkbox]");
    checkboxes.forEach((checkbox) => {
      if (this.isCheckboxChecked(newData, checkbox)) {
        newData = this.handleCheckboxToggled(newData, checkbox, initial);
      }
    });
    return newData;
  }

  async componentDidMount() {
    await this.fetchAll();
    const { store, newStore } = this.props;

    let data;
    if (store) {
      if (newStore) {
        data = this.store.getObject(store);
      } else {
        data = store;
      }
    } else {
      data = this.store.getDefaultData();
    }
    data.bodyToggleGroup = true;
    data.bagToggleGroup = false;
    data.othersusToggleGroup = false;

    if (data.id === null) {
      const onboardingButton = this.state.buttonsTypes.find((button) => button.name === "onboarding-buttons");
      if (onboardingButton) {
        data.buttons_type = onboardingButton.id;
      }
    }

    const redirectStore = null;
    this.setState({ data, redirectStore }, () => {
      const errors = this.validate();
      this.setCheckboxesEventListener();
      let newData = data;
      newData = this.setCheckboxesInitialValues(newData, true);
      this.setState({ data: newData, errors: errors || {} });
    });
  }

  delete = async () => {
    let status = "deleting";
    this.setState({ status });
    try {
      await deleteStore(this.state.data.id);
      status = "deleted";
      this.setState({ status });
    } catch (exception) {
      if (exception.response) {
        toast.error("Failed to delete!");
      } else {
        console.log(exception);
        toast.error("Failed to delete!");
      }
      this.setState({ status });
    }
  };

  save = async () => {
    try {
      const { data: store } = await saveStore(this.state.data);
      if (this.props.newStore) {
        const redirectStore = store.id;
        this.setState({ redirectStore });
      }
      toast.info("Store config updated.");
    } catch (ex) {
      if (ex.response) {
        if (ex.response.status === 400) {
          console.log(ex.response);
          Object.keys(ex.response.data).forEach(function (key) {
            const message = `${key}: ${ex.response.data[key][0]}`;
            toast.error(message);
          });
        } else if (ex.response.status === 404) {
          toast.error("Store not found");
        } else {
          toast.error("Failed to update");
        }
      } else {
        toast.error("Failed to update");
      }
    }
  };

  restartInference = async () => {
    try {
      const { data: res } = await restartLocationContainer(this.state.data.location, "simone-inference");
      toast.info(res.detail);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        if (ex.response.data && ex.response.data.detail) {
          toast.error(ex.response.data.detail);
        } else {
          toast.error("Store not found");
        }
      } else if (ex.response && ex.response.status === 400) {
        Object.keys(ex.response.data).forEach(function (key) {
          const message = `${key}: ${ex.response.data[key][0]}`;
          toast.error(message);
        });
      } else if (ex.response && ex.response.status === 401) {
        toast.error(ex.response.data.error);
      } else {
        toast.error("Unhandled error");
      }
    }
    this.props.toggleRestartMode();
  };

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleLocalInferenceClicked = (checked) => {
    if (checked) {
      this.setState({ data: { ...this.state.data, local_inference: true } });
    } else {
      this.setState({ showLocalInferenceModal: true });
    }
  };

  confirmDisableLocalInference = () => {
    this.setState({
      data: { ...this.state.data, local_inference: false },
      showLocalInferenceModal: false,
    });
  };

  cancelDisableLocalInference = () => {
    this.setState({
      showLocalInferenceModal: false,
    });
  };

  render() {
    const {
      data,
      deepModels,
      alertChannels,
      showNewLabels,
      toggleConfigsOldLabel,
      toggleConfigBodyParent,
      toggleConfigsNewLabelBody,
      toggleConfigBagParent,
      toggleConfigsNewLabelBag,
      toggleConfigOthersusParent,
      toggleConfigsNewLabelOtherSus,
      toggleConfigsDisplay,
      redirectStore,
      slackChannels,
      storeManagers,
      buttonsTypes,
      uploadTargets,
      uploadBuckets,
      blurrings,
      status,
      errors,
      showLocalInferenceModal,
    } = this.state;

    const { newStore, deleteMode, restartMode } = this.props;

    if (redirectStore) {
      return <Redirect to={`/stores/${redirectStore}`} />;
    }

    if (status === "deleted") {
      return <Redirect to="/stores/" />;
    }

    return (
      <Card>
        <CardHeader>
          <h5 className="card-category">Store config</h5>
        </CardHeader>
        <CardBody>
          <FormDataContext.Provider value={data}>
            <Form onSubmit={this.handleSubmit}>
              <ResponsiveRow
                fields={[
                  new FieldBuilder()
                    .setId("location")
                    .setName("location")
                    .setComponent(
                      this.renderInput("location", "Location", null, {
                        disabled: !newStore,
                      })
                    )
                    .build(),
                  new FieldBuilder()
                    .setId("name")
                    .setName("name")
                    .setComponent(this.renderInput("name", "Store name"))
                    .build(),
                  new FieldBuilder()
                    .setId("store_manager")
                    .setName("store_manager")
                    .setComponent(this.renderSelect("store_manager", "Store manager", storeManagers))
                    .build(),
                  new FieldBuilder()
                    .setId("telegram_group_id")
                    .setName("telegram_group_id")
                    .setComponent(this.renderInput("telegram_group_id", "Telegram Group ID"))
                    .build(),
                ]}
              />

              <ResponsiveRow
                className="flex-row-reverse"
                fields={[
                  new FieldBuilder()
                    .setId("send_to_app")
                    .setName("send_to_app")
                    .setComponent(this.renderToggleSwitch("send_to_app", "Send To App"))
                    .build(),
                ]}
              />

              <ResponsiveRow
                fields={[
                  new FieldBuilder()
                    .setId("simulation")
                    .setName("simulation")
                    .setParentTagClassName(data.simulation ? "red-critical pr-md-1" : "pr-md-1")
                    .setComponent(this.renderToggleSwitch("simulation", "Simulation"))
                    .build(),
                  new FieldBuilder()
                    .setId("simulation_threshold")
                    .setName("simulation_threshold")
                    .setComponent(
                      this.renderInput("simulation_threshold", "Simulation threshold", "number", { step: 0.01 })
                    )
                    .build(),
                  new FieldBuilder()
                    .setId("slack_channel")
                    .setName("slack_channel")
                    .setComponent(this.renderSelect("slack_channel", "Slack Channel", slackChannels))
                    .build(),
                  new FieldBuilder()
                    .setId("alert_channel")
                    .setName("alert_channel")
                    .setParentTagClassName(
                      parseInt(data.alert_channel ?? 0) === 0 ? " red-critical pl-md-1" : "pl-md-1"
                    )
                    .setComponent(this.renderSelect("alert_channel", "Alert Channel", alertChannels))
                    .build(),
                ]}
              />

              <ResponsiveRow
                fields={[
                  new FieldBuilder()
                    .setId("alert_expiration")
                    .setName("alert_expiration")
                    .setComponent(
                      this.renderInput("alert_expiration", "Alert expiration (seconds)", "number", {
                        step: 1,
                        min: process.env.REACT_APP_MIN_ALERT_EXPIRATION,
                        max: process.env.REACT_APP_MAX_ALERT_EXPIRATION,
                      })
                    )
                    .build(),
                  new FieldBuilder()
                    .setId("alert_link_expiration_delay")
                    .setName("alert_link_expiration_delay")
                    .setComponent(
                      this.renderInput("alert_link_expiration_delay", "Alert link expiration (seconds)", "number")
                    )
                    .build(),
                ]}
              />

              <ResponsiveRow
                fields={[
                  new FieldBuilder()
                    .setId("buttons_type")
                    .setName("buttons_type")
                    .setComponent(this.renderSelect("buttons_type", "Buttons Type", buttonsTypes))
                    .build(),
                  new FieldBuilder()
                    .setId("store_recheckings")
                    .setName("store_recheckings")
                    .setComponent(
                      this.renderInput("store_recheckings", "Number of Recheckings", "number", {
                        step: 1,
                        min: process.env.REACT_APP_MIN_RECHECKING,
                        max: process.env.REACT_APP_MAX_RECHECKING,
                      })
                    )
                    .build(),
                  new FieldBuilder()
                    .setId("blurring")
                    .setName("blurring")
                    .setComponent(this.renderSelect("blurring", "Blurring", blurrings))
                    .build(),
                  new FieldBuilder()
                    .setId("allow_dataset")
                    .setName("allow_dataset")
                    .setParentTagClassName("pl-md-1")
                    .setComponent(this.renderToggleSwitch("allow_dataset", "Allow Dataset"))
                    .build(),
                ]}
              />

              <ResponsiveRow
                fields={[
                  new FieldBuilder()
                    .setId("local_inference")
                    .setName("local_inference")
                    .setParentTagClassName(data.local_inference ? "pr-md-1" : "orange-critical pr-md-1")
                    .setComponent(
                      <ToggleSwitch
                        name="local_inference"
                        id="local_inference"
                        checked={data.local_inference}
                        onChange={(e) => this.handleLocalInferenceClicked(e.target.checked)}
                        label={data.local_inference ? "Local inference" : "Cloud inference"}
                      />
                    )
                    .build(),
                  new FieldBuilder()
                    .setId("upload_target")
                    .setName("upload_target")
                    .setComponent(this.renderSelect("upload_target", "Upload Target", uploadTargets))
                    .build(),
                  new FieldBuilder()
                    .setId("upload_bucket")
                    .setName("upload_bucket")
                    .setComponent(this.renderSelect("upload_bucket", "Upload Bucket", uploadBuckets))
                    .build(),
                ]}
              />

              <ResponsiveRow
                md="4"
                fields={[
                  new FieldBuilder()
                    .setId("store_favor_factor")
                    .setName("store_favor_factor")
                    .setComponent(
                      this.renderInput("store_favor_factor", "Store favor factor", "number", {
                        step: 0.01,
                        min: process.env.REACT_APP_MIN_FAVOR_FACTOR,
                        max: process.env.REACT_APP_MAX_FAVOR_FACTOR,
                      })
                    )
                    .build(),
                  new FieldBuilder()
                    .setId("see_more_compression")
                    .setName("see_more_compression")
                    .setComponent(
                      this.renderInput("see_more_compression", "See more compression", "number", { step: 0.01 })
                    )
                    .build(),
                  new FieldBuilder()
                    .setId("fps_confidence")
                    .setName("fps_confidence")
                    .setComponent(
                      this.renderInput("fps_confidence", "FPS confidence", "number", {
                        step: 0.01,
                      })
                    )
                    .build(),
                ]}
              />

              <ResponsiveRow
                md="4"
                fields={[
                  new FieldBuilder()
                    .setId("deep_model")
                    .setName("deep_model")
                    .setComponent(this.renderSelect("deep_model", "Deep model", deepModels))
                    .build(),
                  new FieldBuilder()
                    .setId("minimum_alerts_per_hour")
                    .setName("minimum_alerts_per_hour")
                    .setComponent(
                      this.renderInput("minimum_alerts_per_hour", "Minimum alerts per hour", "number", {
                        step: 0.01,
                      })
                    )
                    .build(),
                ]}
              />


              <h5 className="card-category">{showNewLabels ? "Old labels" : "Labels"}</h5>
             
              <ResponsiveRow
                md="4"
                fields={toggleConfigsOldLabel.map((config) =>
                  new FieldBuilder()
                    .setId(config.name)
                    .setName(config.name)
                    .setComponent(
                      this.renderToggleSwitch(
                        config.name,
                        config.value,
                        config.inverted,
                        config.disabled,
                        config.datagroup
                      )
                    )
                    .build()
                )}
              />

              {showNewLabels && (
                <div>
                  <h5 className="card-category">New labels</h5>
                  <ResponsiveRow
                    md="4"
                    bottomSeparator={false}
                    fields={[
                      new FieldBuilder()
                        .setId(toggleConfigBodyParent.name)
                        .setName(toggleConfigBodyParent.name)
                        .setParentTagClassName(data.bodyToggleGroup ? "green pr-md-1" : "pr-md-1")
                        .setComponent(
                          this.renderToggleSwitch(
                            toggleConfigBodyParent.name,
                            toggleConfigBodyParent.value,
                            toggleConfigBodyParent.inverted,
                            toggleConfigBodyParent.disabled,
                            toggleConfigBodyParent.datagroup,
                            toggleConfigBodyParent.parentgroup,
                            toggleConfigBodyParent.childgroup
                          )
                        )
                        .build(),
                    ]}
                  />
                  <ResponsiveRow
                    md="4"
                    fields={toggleConfigsNewLabelBody.map((config) =>
                      new FieldBuilder()
                        .setId(config.name)
                        .setName(config.name)
                        .setComponent(
                          this.renderToggleSwitch(
                            config.name,
                            config.value,
                            config.inverted,
                            config.disabled,
                            config.datagroup,
                            config.parentgroup,
                            config.childgroup
                          )
                        )
                        .build()
                    )}
                  />
                  <ResponsiveRow
                    md="4"
                    bottomSeparator={false}
                    fields={[
                      new FieldBuilder()
                        .setId(toggleConfigBagParent.name)
                        .setName(toggleConfigBagParent.name)
                        .setParentTagClassName(data.bagToggleGroup ? "green pr-md-1" : "pr-md-1")
                        .setComponent(
                          this.renderToggleSwitch(
                            toggleConfigBagParent.name,
                            toggleConfigBagParent.value,
                            toggleConfigBagParent.inverted,
                            toggleConfigBagParent.disabled,
                            toggleConfigBagParent.datagroup,
                            toggleConfigBagParent.parentgroup,
                            toggleConfigBagParent.childgroup
                          )
                        )
                        .build(),
                    ]}
                  />
                  <ResponsiveRow
                    md="4"
                    fields={toggleConfigsNewLabelBag.map((config) =>
                      new FieldBuilder()
                        .setId(config.name)
                        .setName(config.name)
                        .setComponent(
                          this.renderToggleSwitch(
                            config.name,
                            config.value,
                            config.inverted,
                            config.disabled,
                            config.datagroup,
                            config.parentgroup,
                            config.childgroup
                          )
                        )
                        .build()
                    )}
                  />
                  <ResponsiveRow
                    md="4"
                    bottomSeparator={false}
                    fields={[
                      new FieldBuilder()
                        .setId(toggleConfigOthersusParent.name)
                        .setName(toggleConfigOthersusParent.name)
                        .setParentTagClassName(data.othersusToggleGroup ? "green pr-md-1" : "pr-md-1")
                        .setComponent(
                          this.renderToggleSwitch(
                            toggleConfigOthersusParent.name,
                            toggleConfigOthersusParent.value,
                            toggleConfigOthersusParent.inverted,
                            toggleConfigOthersusParent.disabled,
                            toggleConfigOthersusParent.datagroup,
                            toggleConfigOthersusParent.parentgroup,
                            toggleConfigOthersusParent.childgroup
                          )
                        )
                        .build(),
                    ]}
                  />
                  <ResponsiveRow
                    md="4"
                    fields={toggleConfigsNewLabelOtherSus.map((config) =>
                      new FieldBuilder()
                        .setId(config.name)
                        .setName(config.name)
                        .setComponent(
                          this.renderToggleSwitch(
                            config.name,
                            config.value,
                            config.inverted,
                            config.disabled,
                            config.datagroup,
                            config.parentgroup,
                            config.childgroup
                          )
                        )
                        .build()
                    )}
                  />
                </div>
              )}
              <ResponsiveRow
                header="Display"
                md="4"
                fields={toggleConfigsDisplay.map((config) =>
                  new FieldBuilder()
                    .setId(config.name)
                    .setName(config.name)
                    .setComponent(this.renderToggleSwitch(config.name, config.value, config.inverted, config.disabled))
                    .build()
                )}
              />

              <Modal
                isOpen={showLocalInferenceModal}
                toggle={this.cancelDisableLocalInference}
                contentClassName="bg-modal"
              >
                <ModalHeader>Confirm Disable Local Inference</ModalHeader>
                <ModalBody>Are you sure you want to disable local inference?</ModalBody>
                <ModalFooter>
                  <Button className="mr-2" color="danger" onClick={this.confirmDisableLocalInference}>
                    Yes, disable
                  </Button>
                  <Button color="secondary" onClick={this.cancelDisableLocalInference}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>

              <Row>
                {!newStore && !restartMode && (
                  <Col>
                    <Button className="float-left btn-primary m-2" onClick={this.props.toggleRestartMode}>
                      <i className="fas fa-power-off mr-2" /> Restart Inference
                    </Button>
                  </Col>
                )}
                {restartMode && (
                  <Col className="restart-confirmation">
                    <span className="float-left">Are you sure?</span>
                    <Button className="float-left btn-primary m-2" onClick={this.restartInference}>
                      <i className="fas fa-power-off mr-2" /> Restart Inference
                    </Button>

                    <Button className="float-left m-2" onClick={this.props.toggleRestartMode}>
                      <i className="fas fa-times mr-2" />
                      Cancel
                    </Button>
                  </Col>
                )}
                {!deleteMode && (
                  <Col>
                    <Button
                      disabled={Object.keys(errors).length !== 0}
                      className="float-right btn-success m-2"
                      onClick={this.save}
                    >
                      <i className="fas fa-save mr-2" />
                      Save
                    </Button>
                    <DisplayWhen condition={userHasRole(editorRole) && !userHasRole(qualityRole)}>
                      {!newStore && (
                        <Button className="float-right btn-danger m-2" onClick={this.props.toggleDeleteMode}>
                          <i className="fas fa-trash-alt mr-2" /> Delete
                        </Button>
                      )}
                    </DisplayWhen>
                  </Col>
                )}
                {deleteMode && (
                  <Col className="delete-confirmation">
                    <Button className="float-right btn-danger m-2" onClick={this.delete}>
                      <i className="fas fa-trash-alt mr-2" /> Delete
                    </Button>

                    <Button className="float-right m-2" onClick={this.props.toggleDeleteMode}>
                      <i className="fas fa-times mr-2" />
                      Cancel
                    </Button>
                    <span className="float-right">Are you sure?</span>
                  </Col>
                )}
              </Row>
            </Form>
          </FormDataContext.Provider>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(StoreConfigForm);
