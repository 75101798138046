import React, { Component } from "react";
import { Button, Col, Input, InputGroup, InputGroupAddon, Row } from "reactstrap";
import { toast } from "react-toastify";
import triggerRemoteCommand from "../../services/remoteControlService";
import { getServerByHostname, getAllReachableServers, getAuthorizedGroups } from "../../services/serversService";
import userHasRole from "../shared/utils/authUtils";
import { editorRole, viewerRole } from "../shared/constants";
import DisplayWhen from "../common/base/DisplayWhen";

class SelectServer extends Component {
  state = {
    serverName: "",
    server: {},
    filteredServers: [],
    authorizedGroups: [],
    isDropdownOpen: false,
  };

  async componentDidMount() {
    const { server } = this.props;
    if (server) {
      this.setState({ server, serverName: server.hostname }, () => this.pingServer());
    }

    try {
      const response = await getAuthorizedGroups();
      if (response.status === 200) {
        this.setState({ authorizedGroups: response.data.groups || [] });
      } else {
        toast.error("Failed to fetch authorized groups");
      }
    } catch (ex) {
      this.setState({ authorizedGroups: [] });
    }

    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { isDropdownOpen } = this.state;

    if (isDropdownOpen && this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
      this.setState({ isDropdownOpen: false });
    }
  };

  handleSearch = async (value) => {
    const { authorizedGroups } = this.state;
    try {
      const response = await getAllReachableServers({
        search: value,
        groups: authorizedGroups,
      });
      if (response.status === 200) {
        this.setState({ filteredServers: response.data.results || [], isDropdownOpen: true });
      }
    } catch (ex) {
      this.setState({ filteredServers: [] });
    }
  };

  handleChange = (value) => {
    this.setState({ serverName: value }, () => this.handleSearch(value));
  };

  handleSelectServer = async () => {
    const { serverName } = this.state;
    const { handleReachServer } = this.props;
    await getServerByHostname(serverName)
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Server ${serverName} found, let's try reaching it !`);
          this.setState({ server: response.data });
        } else {
          this.setState({ server: {} });
          toast.error("Server not found...");
          return;
        }
        handleReachServer(response.data, false);
        this.pingServer();
      })
      .catch((ex) => {
        console.log(ex.response);
        toast.error(`Server ${serverName}: ${ex.response.status} ${ex.response.statusText}`);
      });
  };

  handleServerSelect = (serverName) => {
    this.setState({ serverName, filteredServers: [], isDropdownOpen: false });
  };

  async pingServer() {
    const { serverName, server } = this.state;
    const { handleReachServer } = this.props;

    if (server === {}) {
      handleReachServer(server, false);
    }

    const data = {};
    data.hostname = serverName;
    data.cmd = "file";
    data.cmdArgs = {
      path: "/etc/hostname",
    };
    data.timeout = 5;

    await triggerRemoteCommand(data)
      .then((response) => {
        if (response.status === "OK") {
          toast.success(`Server ${serverName} is reachable !`);
          handleReachServer(server, true);
        } else {
          toast.error(`Cannot reach ${serverName}...\n${response.stderr}`);
          handleReachServer(server, false);
        }
      })
      .catch(() => {
        toast.error(`Unexpected error for ${serverName}`);
        handleReachServer(server, false);
      });
  }

  render() {
    const { serverName, filteredServers, isDropdownOpen } = this.state;
    const { handleListServersReverseTunnel } = this.props;

    return (
      <Row>
        <Col md="4">
          <InputGroup>
            <Input
              name="server"
              id="server"
              value={serverName}
              placeholder="Choose a server"
              onChange={(e) => this.handleChange(e.currentTarget.value)}
            />
            <InputGroupAddon addonType="append">
              <Button className="input-group-button" onClick={this.handleSelectServer}>
                Select
              </Button>
            </InputGroupAddon>
          </InputGroup>
          {isDropdownOpen && filteredServers && filteredServers.length > 0 && (
            <ul
              className="dropdown-menu show"
              ref={(el) => {
                this.dropdownMenu = el;
              }}
            >
              {filteredServers.map((server) => (
                <li key={server.id} className="dropdown-item" onClick={() => this.handleServerSelect(server.hostname)}>
                  {server.hostname}
                </li>
              ))}
            </ul>
          )}
        </Col>
        <Col md="4" />
        <Col md="4">
          <DisplayWhen condition={userHasRole(editorRole) || userHasRole(viewerRole)}>
            <Button className="float-right btn-success m-2" onClick={handleListServersReverseTunnel}>
              <i className="fas fa-search mr-2" />
              List Servers Reverse Tunnel
            </Button>
          </DisplayWhen>
        </Col>
      </Row>
    );
  }
}

export default SelectServer;
